import React, { useEffect, useState } from "react";
import styles from "./Success.module.scss";
import { Footer } from "../../components/Footer/Footer";
import { Header } from "../../components/Header/Header";
import { useNavigate } from "react-router-dom";

import success from "../../assets/success.svg"

export default function Success() {
    const navigate = useNavigate();
    const [data, setData] = useState(null);
    const handleBack = () => {
        navigate("/");
    }
    useEffect(() => {
        const name =sessionStorage.getItem("userName")
        const lastname =sessionStorage.getItem("userLastName");
        if(name === null || lastname === null){
            return
        }
        setData(`${name} ${lastname}`)
    }, []);
    return (
        <div className={styles.container}>
            <Header/>
            <main className={styles.root}>
                <div className={styles.card}>
                    <div className={styles.containerInfo}>
                        {data ? <><img src={success} alt="success" />
                        <div>
                            <h3>Bienvenido {data},</h3>
                            <h3>¡Ingresaste Exitosamente!</h3>
                        </div>
                        <p>Ya puedes disfrutar de tu cuenta en <b>Produbanco</b></p></> : <div><h3>Usuario no registrado</h3></div>}
                    </div>
                    <button
                        className={styles.button}
                        onClick={handleBack}
                    >
                        Continuar
                    </button>
                </div>
            </main>
            <Footer/>
        </div>
    );
}
