import React from "react";
import styles from "./Requisitos.module.scss";
import { useNavigate } from "react-router";
import { Header } from "../../components/Header/Header";
import { Footer } from "../../components/Footer/Footer";

import qr from "../../assets/Qr_MIA_Puro.png"
import check from "../../assets/check.svg"

const steps = [
    {
        title: "Descarga la Aplicación",
        id: 1
    },
    {
        title: "Escanea tu documento de identidad",
        id: 2
    },
    {
        title: "Tómate una selfie",
        id: 3
    },
    {
        title: "¡Listo!",
        id: 4
    }
]

export default function Requisitos() {
    const navigate = useNavigate();

    const handleBack = () => {
        navigate("/");
    }

    return (
        <div className={styles.container}>
            <Header/>
            <main className={styles.root}>
                <div className={styles.card}>
                    <div className={styles.containerInfo}>
                        <h3>Ahora puedes crear tu cuenta desde la app de MIAid</h3>
                        <p>Escanea el QR con la cámara de tu celular y sigue los pasos</p>
                    </div>
                    <div className={styles.containerQr}>
                        <div>
                            <img src={qr} alt="QR" className={styles.qr} />
                        </div>
                        <div>
                            {steps.map((step) => (
                                <div className={styles.containerStep} key={step.id}>
                                    <img src={check} alt="Check" />
                                    <p>{step.title}</p>
                                </div>
                            ))}
                            {/* <div className={styles.containerStores}>
                                <a href="https://play.google.com/store/apps" target="_blank" rel="noreferrer">
                                <img src={playStore} alt="Play Store" />
                                </a>
                                <a href="https://apps.apple.com/ar/app" target="_blank" rel="noreferrer">
                                    <img src={appStore} alt="App Store" />
                                </a>
                            </div> */}
                        </div>
                    </div>
                    <button
                        className={styles.button}
                        onClick={handleBack}
                    >
                        Regresar
                    </button>
                </div>
            </main>
            <Footer/>
        </div>
    );
}
